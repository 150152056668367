import { Client } from 'typesense'
import { LIMITS } from '@framework/utils/limits'
import { getCollectionName } from '@utils/helper'

export const TYPESENSE_CONFIG = {
  nodes: [
    {
      host: String(process.env.NEXT_PUBLIC_TS_HOST),
      port: Number(process.env.NEXT_PUBLIC_TS_PORT),
      protocol: String(process.env.NEXT_PUBLIC_TS_PROTOCOL),
      path: process.env.NEXT_PUBLIC_TS_PATH || '',
    },
  ],
  apiKey: String(process.env.NEXT_PUBLIC_TS_API_KEY),
  connectionTimeoutSeconds: 10,
}

const typesense = new Client(TYPESENSE_CONFIG)

export const getProduct = async (id: number, locale: string) => {
  const collectionName = getCollectionName(locale)

  if (id && id.toString() !== '') {
    try {
      const document = await typesense
        .collections(collectionName)
        .documents(id.toString())
        .retrieve()
      return document
    } catch (error) {
      if (error) {
        console.warn(
          `Document with id ${id} not found in collection ${collectionName}`,
        )
        return null // Or handle it with a custom response
      } else {
        console.error('Error retrieving product:', error)
        throw error
      }
    }
  }
  return null
}
export const getProducts = async (ids: any, locale: string) => {
  const collectionName = getCollectionName(locale)

  let searchParameters = {
    q: `*`,
    query_by: `name`,
    filter_by: `sku:= [${ids}] && is_active: true`,
  }
  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)
  return result.hits
}

export const getBestSellersProducts = async (
  locale: string,
  addl_params?: any,
) => {
  const collectionName = getCollectionName(locale)

  let searchParameters: any = {
    q: `*`,
    query_by: 'name',
    filter_by: `${
      addl_params?.addl_filters ? addl_params.addl_filters : ''
    } && is_active: true`,
    sort_by: 'sales_count:desc',
    ...(addl_params?.page && { page: addl_params.page }),
    ...(addl_params?.per_page && { per_page: addl_params.per_page }),
  }
  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)
  return result.hits
}

export const getFeaturedProducts = async (locale: string) => {
  return await getFlaggedProducts('featured', locale)
}

export const getTrendingProducts = async (locale: string) => {
  return await getFlaggedProducts('trending', locale)
}
export const getDirectDealProducts = async (locale: string) => {
  return await getFlaggedProducts('direct_deals', locale)
}
export const getNewArrivalProducts = async (locale: string) => {
  return await getFlaggedProducts('new_arrivals', locale)
}
export const getLiquidationProducts = async (locale: string) => {
  return await getFlaggedProducts('liquidation', locale)
}
export const getFeaturedBrandProducts = async (locale: string, params: any) => {
  return await getFlaggedProducts('hp_brand_sku', locale, params)
}

export const getAllBrandProducts = async (locale: string, params: any) => {
  return await getBrandProducts('*', locale, params)
}

export const getFeaturedCategoryProducts = async (
  locale: string,
  params: any,
) => {
  const collectionName = getCollectionName(locale)
  const { categoryLevel, categoryName } = params

  let searchParameters = {
    q: `***`,
    query_by:
      'name,category,description,category_l4,category_l3,category_l2,category_l1,manufacturer,brand',
    filter_by: `category_l${categoryLevel}:=${categoryName} && is_active: true && search_visible:!= false`,
    sort_by: `_eval(is_in_stock:true):desc,sales_count:desc,_text_match:desc`,
    per_page: 14,
  }
  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)
  const featuredProducts: any = []
  result.hits?.map((hit) => featuredProducts.push(hit.document))
  return featuredProducts
}

export const getRelatedProducts = async (product: any, locale: string) => {
  const collectionName = getCollectionName(locale)

  let searchParameters = {
    q: `${product.category}`,
    query_by:
      'name,category,description,category_l4,category_l3,category_l2,category_l1,manufacturer,brand',
    filter_by: `sku:!=${product.sku} && is_active: true`,
  }
  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)
  const relatedProducts: any = []
  result.hits?.map((hit) => relatedProducts.push(hit.document))
  return relatedProducts
}

export const getRelatedCartProducts = async (
  categories: any,
  excludedSkus: any,
  locale: string,
) => {
  const collectionName = getCollectionName(locale)

  const categoriesQuery = categories.join(',')

  const excludedSkusFilter = excludedSkus
    .map((sku) => `sku:!=${sku}`)
    .join(' && ')

  const searchParameters = {
    q: categoriesQuery,
    query_by:
      'name,category,description,category_l4,category_l3,category_l2,category_l1,manufacturer,brand',
    filter_by: `${excludedSkusFilter} && is_active:true`,
  }

  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)

  const relatedCartProducts: any[] =
    result.hits?.map((hit) => hit.document) || []

  return relatedCartProducts
}

const getFlaggedProducts = async (
  flag: string,
  locale: string,
  params?: any,
) => {
  const collectionName = getCollectionName(locale)

  let searchParameters = {
    q: `${flag}`,
    query_by: 'flags',
    filter_by: `is_active: true`,
    sort_by: 'price:desc',
    ...(params?.page && { page: params.page }),
    ...(params?.perPage && { per_page: params.perPage }),
  }
  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)

  return result
}

const getBrandProducts = async (key: string, locale: string, params?: any) => {
  const { brand, searchQuery, page, perPage, sort } = params

  const collectionName = getCollectionName(locale)

  let searchParameters = {
    q: `${searchQuery.length === 0 ? key : searchQuery}`,
    query_by: `${searchQuery.length === 0 ? 'brand' : 'name'}`,
    filter_by: `${params?.brand && `brand:="${brand}" &&`} is_active: true`,
    sort_by: sort ? sort : 'price:desc',
    ...(params?.page && { page: page }),
    ...(params?.perPage && { per_page: perPage }),
  }

  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)

  return result
}

export interface ProductsByCategoryNameParams {
  name: string
  level: number
  locale: string
  page?: number
  sort?: string
  filter: string
}

export const getProductsByCategoryName = async (
  params: ProductsByCategoryNameParams,
) => {
  const { name, level, locale, page, sort, filter } = params

  const collectionName = getCollectionName(locale)
  const filterCondition = filter
    ? `${filter} && is_active: true`
    : `is_active: true`

  const sortBy =
    sort && sort === 'popular'
      ? 'flag:desc'
      : sort && sort === 'lowest'
      ? 'price:asc'
      : sort && sort === 'highest'
      ? 'price:desc'
      : sort && sort === 'most-order'
      ? ''
      : ''
  let searchParameters = {
    q: `*`,
    query_by: `name`,
    filter_by: filterCondition.trim(),
    sort_by: sortBy,
    facet_by: `category_l4,manufacturer,brand`,
    max_facet_values: 30,
    per_page: page
      ? Math.min(page * LIMITS.PRODUCTS_LIMITS, 250)
      : LIMITS.PRODUCTS_LIMITS,
  }
  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)

  return result
}

export const getCategories = async (
  level: number,
  locale: string,
  parent_category?: string,
) => {
  const collectionName = getCollectionName(locale)

  let searchParameters: any = {
    q: `*`,
    query_by: `name`,
    filter_by: `is_active: true`,
    facet_by: `category_l${level}`,
  }
  if (parent_category) {
    searchParameters.filter_by = `category_l${
      Number(level) - 1
    }:="${parent_category}"`
  }
  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)
  return result.facet_counts
}

export const getAllLevelCategories = async (level: number, locale?: string) => {
  const collectionName = getCollectionName(locale)

  let searchParameters: any = {
    q: `*`,
    query_by: `category_l${level}`,
    filter_by: `is_active: true`,
    facet_by: `category_l${level}`,
    max_facet_values: 100000,
  }
  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)
  return result.facet_counts
}

export const getFacetItemsList = async (locale: string, facetBy) => {
  const collectionName = getCollectionName(locale)

  let searchParameters: any = {
    q: `*`,
    query_by: `name,${facetBy.toString()},description`,
    filter_by: `is_active: true`,
    facet_by: facetBy ? facetBy : `brand`,
    highlight_full_fields: `name,${facetBy.toString()},description`,
    max_facet_values: 999999999,
  }

  const result = await typesense
    .collections(collectionName)
    .documents()
    .search(searchParameters)

  const resultList = []
  result.facet_counts[0]?.counts?.map((item) => resultList.push(item.value))

  return resultList.sort()
}

export const getSiteMap = async (page: number) => {
  let searchParameters = {
    q: `*`,
    query_by: 'link',
    per_page: 250,
    page: page,
  }
  const result = await typesense
    .collections('sitemap')
    .documents()
    .search(searchParameters)
  return result
}
