import { MdOutlineCalendarMonth } from 'react-icons/md'

interface ExpectedDeliveryProps {
  deliveryBy?: string
  icon?: boolean
}

const ExpectedDelivery: React.FC<ExpectedDeliveryProps> = ({
  deliveryBy,
  icon = false,
}) => {
  if (!deliveryBy) return null

  return (
    <div className="flex items-start space-x-2 text-brand-green">
      {icon && (
        <div className="flex-shrink-0">
          <MdOutlineCalendarMonth className="w-8 h-8 text-black" />
        </div>
      )}
      <div>
        <p className="text-sm mt-2">{deliveryBy}</p>
      </div>
    </div>
  )
}

export default ExpectedDelivery
