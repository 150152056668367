export const setBuyNowToken = (tokenKey: any, tokenValue: any) => {
  localStorage.setItem(tokenKey, tokenValue)
}

export const getBuyNowToken = (tokenKey: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(tokenKey)
  }
  return null
}
