import { OrderItem, Product } from '@framework/types'
import * as typesense from '../typesense'
import { Item, generateCartItem } from '@utils/generate-cart-item'
import { convertProduct } from '@framework/utils/convert-product'
import { productPlaceholder } from '@assets/placeholders'
import { OrderItemCard } from '@components/order/order-details'

export const getProductsBySkus = async (
  skus: string,
  customerItems: Item[],
  locale: any,
) => {
  const hits: any = await typesense.getProducts(skus, locale)
  const products: Item[] = []
  customerItems.map((customerItem: Item) => {
    let item = hits.find(
      (h: { document: Product }) => customerItem.sku === h.document?.sku,
    )
    if (!item) return
    item.document.click_and_collect = customerItem?.click_and_collect
    item.document.wishlist_item_id = customerItem?.wishlist_item_id
    item.document.is_addtocart = customerItem?.is_addtocart
    item.document.price = customerItem?.price
    item.document.delivery_days_data = customerItem?.delivery_days_data
      ? JSON.parse(customerItem.delivery_days_data as string)
      : null

    item.document.quantity = customerItem?.saved_list_qty
      ? Number(customerItem?.saved_list_qty)
      : customerItem?.qty
    products.push(generateCartItem(item.document))
  })

  return products as Item[]
}

export const getOrderProducts = async (
  skus: string,
  orderItems: OrderItem[],
  locale: string,
) => {
  const hits: any = await typesense.getProducts(skus, locale)
  let items: any = { 'Pickup Items': [], 'Delivery items': [] }
  orderItems.map((orderItem: OrderItem, index: number) => {
    let item = hits.find(
      (h: { document: Product }) => orderItem.sku === h.document.sku,
    )
    const { gallery } = convertProduct(item.document)
    orderItem.image =
      (item.document['image.thumbnail'] || gallery?.[0]?.thumbnail) ??
      productPlaceholder
    if (orderItem?.extension_attributes?.click_and_collect)
      items['Pickup Items'].push(
        <OrderItemCard key={index} index={index} product={orderItem} />,
      )
    else
      items['Delivery items'].push(
        <OrderItemCard key={index} index={index} product={orderItem} />,
      )
  })

  return items
}
