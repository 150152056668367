import {
  CARRIER_CODE_OMS,
  CARRIER_CODE_PUROLATOR,
  CUSTOMER_GROUPS,
  FLAGS,
  STOCK_TYPE,
} from '@constants/index'
import { Item } from '@contexts/cart/cart.utils'
import { getSession, useSession } from 'next-auth/react'
import nodeSessionStorage from 'sessionstorage-for-nodejs'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import { TYPESENSE_CONFIG } from '@framework/typesense'
import { useRouter } from 'next/router'
import * as moment from 'moment'
import { useContext } from 'react'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import { Session } from 'next-auth'

type PriceDetails = {
  special_price: number
  special_from_date: string | null
  special_to_date: string | null
  price: number
  catalog_rule_price: any
}

interface ProductData {
  flags?: string[]
  stock_type?: string
  order_item_status?: string
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}

export const isSpecialOrder = (data?: ProductData): boolean =>
  !!data &&
  (data.flags?.includes(FLAGS.SPECIAL_ORDER) ||
    data.stock_type === STOCK_TYPE.SPECIAL_ORDER ||
    data.order_item_status === STOCK_TYPE.SPECIAL_ORDER)

export const createPricePayload = ({
  special_price,
  special_from_date,
  special_to_date,
  price,
  catalog_rule_price,
}: Item): PriceDetails => {
  return {
    special_price,
    special_from_date,
    special_to_date,
    price,
    catalog_rule_price,
  }
}

export const getCurrentPrice = ({
  special_price,
  special_from_date,
  special_to_date,
  price,
}) => {
  let todayDate = new Date().toISOString().split('T')[0]
  const specialPrice = Number(special_price)
  const originalPrice = Number(price)

  if (specialPrice == null) {
    return originalPrice // show price if special price null
  } else if (specialPrice && specialPrice >= originalPrice) {
    return originalPrice
  } // show original price if special price is greater
  else if (
    specialPrice &&
    (!special_from_date || special_from_date === '') &&
    (!special_to_date || special_to_date === '')
  ) {
    return specialPrice // always show special price
  } else if (
    specialPrice &&
    todayDate >= special_from_date &&
    todayDate <= special_to_date
  ) {
    return specialPrice // special price show according to date
  } else if (
    specialPrice &&
    todayDate >= special_from_date &&
    special_to_date == null
  ) {
    return specialPrice // special_to_date is null
  } else if (
    specialPrice &&
    special_from_date == null &&
    todayDate <= special_to_date
  ) {
    return specialPrice // special_from_date is null
  } else {
    return originalPrice
  }
}

export const getOldPrice = ({
  special_price,
  special_from_date,
  special_to_date,
  price,
  catalog_rule_price,
}) => {
  const { data: session } = useSession()
  const { user } = useContext(UserContext) as IUser
  let todayDate = new Date().toISOString().split('T')[0]
  const specialPrice = Number(special_price)
  const originalPrice = Number(price)
  const matchingRule = catalog_rule_price?.find(
    (rule: any) =>
      (session?.isGuest && rule.customer_group_id === '0') ||
      rule.customer_group_id === user?.group_id,
  )
  const rulePrice = parseFloat(matchingRule?.rule_price)

  if (
    rulePrice &&
    rulePrice < originalPrice &&
    (!specialPrice || rulePrice < specialPrice)
  ) {
    return originalPrice
  }
  if (specialPrice && specialPrice >= originalPrice) {
    return null
  } else if (
    specialPrice &&
    (!special_from_date || special_from_date === '') &&
    (!special_to_date || special_to_date === '')
  ) {
    return originalPrice
  } else if (
    specialPrice &&
    todayDate >= special_from_date &&
    todayDate <= special_to_date
  ) {
    return originalPrice
  } else if (
    specialPrice &&
    todayDate >= special_from_date &&
    special_to_date == null
  ) {
    return originalPrice
  } else if (
    specialPrice &&
    special_from_date == null &&
    todayDate <= special_to_date
  ) {
    return originalPrice
  }
}

export const getShipInDays = (
  ships_in_days: number,
  translate: (key: string) => string,
) => {
  let value: any, unit: string

  if (ships_in_days === 0) {
    ;[value, unit] = ['1', 'day']
  } else if (ships_in_days >= 21 && ships_in_days <= 30) {
    ;[value, unit] = ['3-4', 'week']
  } else if (ships_in_days > 30) {
    ;[value, unit] = ['6-7', 'week']
  } else {
    ;[value, unit] = [`${ships_in_days}`, 'day']
  }

  const shipsInDaysTranslationKeys = { day: 'text-day', week: 'text-week' }

  return {
    value,
    unit: translate(shipsInDaysTranslationKeys[unit]),
  }
}

export const cleanURLParams = (url) => {
  // Define unwanted characters
  const unwantedChars = /[<>#%{}/;#@%^*()!-=+,.?:|\\^~\[\]`]/g
  // Replace unwanted characters with an empty string
  return url.replace(unwantedChars, '')
}

export const getCollectionName = (locale?: string): string => {
  // If no locale provided, default to English Canada
  const normalizedLocale = locale?.toLowerCase() ?? 'en-ca'

  switch (normalizedLocale) {
    case 'en-us':
      return String(process.env.NEXT_PUBLIC_TS_EN_US_REPO ?? '')
    case 'en-ca':
      return String(process.env.NEXT_PUBLIC_TS_EN_CA_REPO ?? '')
    case 'fr-ca':
      return String(process.env.NEXT_PUBLIC_TS_FR_CA_REPO ?? '')
    default:
      throw new Error(`Unsupported locale: ${locale}`)
  }
}

export const updateSessionStore = async (sessionObj = null) => {
  const session = sessionObj ? sessionObj : await getSession()

  sessionStorage?.setItem('session', JSON.stringify(session))
  nodeSessionStorage?.setItem('session', JSON.stringify(session))
}

export const getSessionStore = () => {
  // Check if sessionStorage is defined before using it
  const sessionStore =
    typeof sessionStorage !== 'undefined' ? sessionStorage : nodeSessionStorage

  const sessionData =
    typeof sessionStorage !== 'undefined'
      ? JSON.parse(sessionStore.getItem('session'))
      : nodeSessionStorage.getItem('session')

  return sessionData
}

export const deleteSessionStore = () => {
  // Check if sessionStorage is defined before using it
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.removeItem('session')
  }
  nodeSessionStorage.removeItem('session')
}

export const getNameSlug = (brandName) => {
  return brandName
    .toLowerCase()
    .replaceAll('/', '-')
    .replaceAll('-', '')
    .replaceAll(' ', '-')
    .replaceAll('---', '-')
    .replaceAll('--', '-')
}

export const filterAndSortCustomerGroups = (
  groups: { label: string }[],
): { label: string }[] => {
  const excludedLabels: string[] = [
    CUSTOMER_GROUPS.NOT_LOGGED_IN as string,
    CUSTOMER_GROUPS.SELECT as string,
  ]

  return groups
    .filter((item) => !excludedLabels.includes(item.label))
    .sort((a, b) =>
      a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }),
    )
}

export const getCategoryFilterString = (
  level: number,
  categoryName: string,
) => {
  return `is_active: true && search_visible:!= false && category_l${level}:= "${categoryName}"`
}

export const numberWithCommas = (num) =>
  num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || num

export const deduplicateRefinement = (items: any, translate: any) => {
  /**
   * note that translate() was attempted dynamically (using item.label or value as part of the translation key),
   * but that generated new bugs...
   *  */
  const newItems = []
  items.forEach((item: any) => {
    item.label =
      item.label.indexOf('category_l1') !== -1
        ? translate('text-department') + ':'
        : item.label.indexOf('ships_in_days') !== -1
        ? `${translate('text-ships-in')} ${translate('text-days')}:`
        : item.label.indexOf('_rebate_') !== -1
        ? translate('text-facet-is-rebate-eligible')
        : item.label.indexOf('new_arrival') !== -1
        ? translate('text-facet-is-new-arrival')
        : item.label.indexOf('liquidation') !== -1
        ? translate('text-facet-is-liquidation')
        : item.label.indexOf('in_stock') !== -1
        ? translate('text-facet-is-in-stock')
        : item.label.indexOf('sold_as') !== -1
        ? translate('text-facet-sold-as')
        : item.label.indexOf('stock_type') !== -1
        ? translate('text-facet-stock-type')
        : item.label

    for (let i = 0; i < item?.items?.length; i++) {
      item.items[i].label =
        item.items[i].label.indexOf('true') !== -1
          ? translate('text-facet-true')
          : item.items[i].label.indexOf('false') !== -1
          ? translate('text-facet-false')
          : item.items[i].label == 'D'
          ? translate('text-facet-D')
          : item.items[i].label == 'R'
          ? translate('text-facet-R')
          : item.items[i].label == 'F'
          ? translate('text-facet-F')
          : item.items[i].label == 'FSD_US'
          ? translate('text-facet-fsd-us')
          : item.items[i].label
    }
    if (
      newItems.filter((e: any) => e.attribute === item.attribute).length === 0
    ) {
      newItems.push(item)
    }
  })
  return newItems
}

export const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_CONFIG,
  additionalSearchParameters: {
    query_by:
      'category,category_l4,name,food_properties,brand,manufacturer,category_l3,category_l2,upc,gtin,sku,mpn,product_id',
    sort_by: '_eval(is_in_stock:true):desc,sales_count:desc,_text_match:desc',
  },
})

export const typesenseInstantsearchAdapterNumber =
  new TypesenseInstantSearchAdapter({
    server: TYPESENSE_CONFIG,
    additionalSearchParameters: {
      query_by:
        'sku,upc,gtin,mpn,product_id,category,category_l4,name,food_properties,brand,manufacturer,category_l3,category_l2',
      sort_by: '_eval(is_in_stock:true):desc,sales_count:desc,_text_match:desc',
    },
  })

export const replaceDomain = (url, newDomain) => {
  // Create URL object from input
  const urlObj = new URL(url)

  // Create new URL with the new domain and original pathname + search
  const newUrl = new URL(urlObj.pathname + urlObj.search, `${newDomain}`)

  return newUrl.toString()
}

export const getDiscountedPrice = (
  userGroupId: string | undefined,
  catalogRulePrices: { customer_group_id: string; rule_price: string }[],
  defaultPrice: number,
  session: Session | null,
  tierPrices: Record<
    string,
    {
      price_qty: string
      cust_group?: string
      all_groups?: string
      website_price: string
    }
  > | null,
): number => {
  const oneQtytierPriceEntry =
    (tierPrices &&
      Object.values(tierPrices).find(
        (tier) =>
          parseFloat(tier.price_qty) === 1 &&
          tier.cust_group?.toString() === userGroupId,
      )) ||
    (tierPrices &&
      Object.values(tierPrices).find(
        (tier) => parseFloat(tier.price_qty) === 1 && tier.all_groups === '1',
      ))

  const matchingRule = catalogRulePrices?.find(
    (rule) =>
      (session?.isGuest && rule.customer_group_id === '0') ||
      rule.customer_group_id === userGroupId,
  )

  const rulePrice = matchingRule
    ? parseFloat(matchingRule.rule_price)
    : Infinity

  const tierPrice = oneQtytierPriceEntry
    ? parseFloat(oneQtytierPriceEntry.website_price)
    : Infinity

  return Math.min(defaultPrice, rulePrice, tierPrice)
}

export const useLocale = () => {
  const { locale } = useRouter()
  return locale
}

export const useIsUSLocale = () => {
  const locale = useLocale()
  return locale?.toLowerCase() === 'en-us'
}

export const useIsCanadaLocale = () => {
  const locale = useLocale()
  return locale?.toLowerCase() === 'en-ca'
}

export const useIsFrenchLocale = () => {
  const locale = useLocale()
  return locale?.toLowerCase() === 'en-fr'
}

export const getExpectedDeliveryDate = (
  method?: any,
  estimatedArrival?: any,
) => {
  if (!method) return ''

  let date: any

  switch (method.carrier_code) {
    case CARRIER_CODE_PUROLATOR:
    case CARRIER_CODE_OMS:
      date = method.extension_attributes?.expected_delivery_date
      break
    default:
      date =
        estimatedArrival?.TimeInTransit?.ServiceSummary?.EstimatedArrival
          ?.Arrival?.Date
  }

  return date ? moment(date).format('DD-MM-YYYY') : ''
}

export const calculateTotalQuantity = (
  items: { quantity?: number }[] | undefined,
  hasBuyNowItems: (items: any[]) => boolean,
  getFilteredItems: (items: any[]) => any[],
): number => {
  if (!items) return 0

  const filteredItems = hasBuyNowItems(items) ? getFilteredItems(items) : items

  return filteredItems.reduce((sum, item) => sum + (item.quantity || 1), 0)
}

export const getProductIdFromUrl = (url: string): number | null => {
  const match = url.match(/(\d+)\.html$/)
  return match ? Number(match[1]) : null
}
