import Modal from '@components/common/modal/modal'
import dynamic from 'next/dynamic'
import {
  useModalAction,
  useModalState,
} from '@components/common/modal/modal.context'
const LoginForm = dynamic(() => import('@components/auth/login-form'))
const SignUpForm = dynamic(() => import('@components/auth/sign-up-form'))
const ForgetPasswordForm = dynamic(
  () => import('@components/auth/forget-password-form'),
)
const ProductPopup = dynamic(() => import('@components/product/product-popup'))
const AddressPopup = dynamic(
  () => import('@components/common/form/checkout-address'),
)
const PaymentPopup = dynamic(
  () => import('@components/common/form/add-payment'),
)
const PhoneNumberPopup = dynamic(
  () => import('@components/common/form/checkout-contact-information'),
)
const DeliveryAddresses = dynamic(
  () => import('@components/address/delivery-addresses'),
)
const CategoryPopup = dynamic(
  () => import('@components/category/category-popup'),
)

const QuotePopup = dynamic(
  () => import('@components/product/product-custom-quote'),
)
const ReferralPopup = dynamic(
  () => import('@components/my-account/referral-popup'),
)

const ChangeZipPopup = dynamic(
  () => import('@components/product/product-change-zip'),
)
const AskQuestion = dynamic(
  () => import('@components/product/product-reviews-questions/ask-question'),
)
import ClickCollectPopup from '@components/product/clickcollect-popup'
import WriteReviews from '@components/product/product-reviews-questions/write-reviews'
import ThumbnailPopup from '@components/product/product-thumbnail-popup'
import CreateNewList from '@components/product/product-addtolist-popup'
import SaveListPopUp from '@components/savelist/savelist-popup'
import VerifyAddressPopup from '@components/checkout/verify-address-popup'
import RebateWelcomPopUp from '@components/rebate/rebate-welcome-popup'
import RebatePdpPopup from '@components/rebate/rebate-pdp-popup'

export default function ManagedModal() {
  const { isOpen, view, data } = useModalState()
  const { closeModal } = useModalAction()

  if (view === 'CATEGORY_VIEW')
    return (
      <Modal open={isOpen} onClose={closeModal} variant="bottom">
        {view === 'CATEGORY_VIEW' && <CategoryPopup />}
      </Modal>
    )

  if (['VERIFY_ADDRESS_POPUP', 'REBATE_WELCOME_POPUP'].includes(view))
    return (
      <Modal open={isOpen}>
        {view === 'VERIFY_ADDRESS_POPUP' && <VerifyAddressPopup data={data} />}
        {view === 'REBATE_WELCOME_POPUP' && <RebateWelcomPopUp data={data} />}
      </Modal>
    )

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {view === 'LOGIN_VIEW' && <LoginForm />}
      {view === 'SIGN_UP_VIEW' && <SignUpForm />}
      {view === 'FORGET_PASSWORD' && <ForgetPasswordForm />}
      {view === 'PRODUCT_VIEW' && <ProductPopup />}
      {view === 'ADDRESS_VIEW_AND_EDIT' && <AddressPopup />}
      {view === 'PAYMENT' && <PaymentPopup />}
      {view === 'PHONE_NUMBER' && <PhoneNumberPopup />}
      {view === 'DELIVERY_VIEW' && <DeliveryAddresses />}
      {view === 'CUSTOM_QUOTE' && <QuotePopup />}
      {view === 'REFERRAL_CREATE_ACCOUNT' && (
        <ReferralPopup startShopping={false} refAmount={data?.ref_amount} />
      )}
      {view === 'REFERRAL_START_SHOPPING' && (
        <ReferralPopup startShopping={true} refAmount={data?.ref_amount} />
      )}
      {view === 'CHANGE_ZIP' && <ChangeZipPopup />}
      {view === 'ASK_QUESTION' && <AskQuestion product_id={data.product_id} />}
      {view === 'WRITE_REVIEWS' && <WriteReviews sku={data.sku} />}
      {view === 'CLICKCOLLECT_POPUP' && <ClickCollectPopup />}
      {view === 'THUMBNAIL_POPUP' && (
        <ThumbnailPopup product={data.product} gallery={data.gallery} />
      )}

      {view === 'CREATE_NEW_LIST_POPUP' && <CreateNewList data={data} />}
      {view === 'SAVE_LIST_POPUP' && <SaveListPopUp data={data} />}
      {view === 'REBATE_PDP_POPUP' && <RebatePdpPopup data={data} />}
    </Modal>
  )
}
