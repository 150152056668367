import axios from 'axios'
import { signOut } from 'next-auth/react'
import Router from 'next/router'
import { deleteSessionStore } from './helper'
import { ROUTES } from './routes'

const apiRestInstance = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_MAG_URL + 'rest/V1' || '',
  })
  axiosInstance.interceptors.response.use(
    (response: any) => {
      try {
        if (typeof response.data === 'string')
          response.data = JSON.parse(response.data)
      } catch {
        return Promise.resolve(response.data)
      }

      return Promise.resolve(
        Array.isArray(response.data) && response.data.length === 1
          ? response.data[0]
          : response.data,
      )
    },
    async (error) => {
      const parsedUrl = new URL(error?.response?.request?.responseURL)
      const responseStatus = error?.response?.status
      const responseMessage = error?.response?.data
        ? error?.response?.data?.message
        : error?.response?.message

      const responseHasError =
        responseMessage ===
        "The consumer isn't authorized to access %resources."

      const isCartIdFaulty =
        responseMessage?.includes('No such entity') ||
        responseMessage?.includes('%fieldName')

      // Access the pathname property to get the desired string
      const pathString = parsedUrl?.pathname

      if (
        (responseStatus === 401 &&
          pathString !== '/rest/V1/customers/me/password') ||
        (isCartIdFaulty && pathString.includes('/rest/V1/fsd/guest-carts')) ||
        responseHasError
      ) {
        await signOut({ redirect: false })
        deleteSessionStore()
        isCartIdFaulty && localStorage.setItem('cart-error', 'true')
        localStorage.setItem('logged-out', 'true')
        Router.push(ROUTES.HOME)
      }

      return Promise.reject(error)
    },
  )
  return axiosInstance
}

export { apiRestInstance }
