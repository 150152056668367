import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { cartReducer, State, initialState } from './cart.reducer'
import {
  ICartPayload,
  IShippingMethod,
  IShppingInfoPayload,
  Item,
  getItem,
  inStock,
} from './cart.utils'
import { useLocalStorage } from '@utils/use-local-storage'
import ENDPOINT from '@utils/restendpoints'
import { getSession, useSession } from 'next-auth/react'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import { useLazyRestApi } from '@hooks/useRestApi'
import cartServices from '@services/cartservices'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { useTranslation } from 'next-i18next'
import ga4Services from '@services/ga4services'
import {
  CARRIER_CODE_PUROLATOR,
  CONFIGURABLE_PRODUCT_TYPE,
  ITEM_DOESNOT_EXIST_ERROR,
} from '@constants/index'
import StoreLocationIcon from '@components/icons/store-location'
import { FiTruck } from 'react-icons/fi'
import checkoutServices from '@services/checkoutservices'
import { ITEM_ADDTOCART_MAX_LIMIT } from '../../constants'
import { getSessionStore } from '@utils/helper'
import { ROUTES } from '@utils/routes'
import Router from 'next/router'
import { BUYNOW_TEMPORARY_TOKEN } from '@constants/index'
import { getBuyNowToken } from '@utils/localStorage'

interface CartProviderState extends State {
  addBuyNowItemToCart: (item: Item, quantity: number) => void
  addItemToCart: (
    item: Item,
    quantity: number,
    selectedOptions?: object,
  ) => void
  updateCartItem: (item: Item, qty: number) => void
  clearItemFromCart: (id: Item['id']) => void
  clearBuyNowItemFromCart: (id: Item['id']) => void
  getItemFromCart: (id: Item['id']) => any | undefined
  isInCart: (id: Item['id']) => boolean
  isInStock: (id: Item['id']) => boolean
  resetCart: () => void
  addToBuyNowApi: () => void
  logoutResetCart: () => void
  logoutBuyNowResetCart: () => void
  callShippingInformation: () => Promise<void>
  shippingMethods: []
  availablePaymentMethods: []
  selectedShippingMethod: any
  loadingShipping: boolean
  handleShippingMethodSelection: (params: any) => void
  setLoadingShipping: any
  updatingShipping: any
  setUpdatingShipping: any
  setAvailablePaymentMethods: any
  setShippingMethods: Dispatch<SetStateAction<any>>
  setSelectedShippingMethod: Dispatch<SetStateAction<any>>
  groupItems: any
  isOnlyPickUp: boolean
  isPartial: boolean
  isOnlyDelivery: boolean
  addOrUpdateItemsHandler: (items: Item[]) => void
  isUpdatingQty: boolean
  setIsUpdatingQty: Dispatch<SetStateAction<boolean>>
  freeOmsshippingMethodAvailable: boolean
  setFreeOmsshippingMethodAvailable: (value: boolean) => void
  isMiniCartitem
  setIsMiniCartItem
  isPromoApplied: boolean
  setisPromoApplied: Dispatch<SetStateAction<boolean>>
}
export const cartContext = createContext<CartProviderState | undefined>(
  undefined,
)

cartContext.displayName = 'CartContext'

const MULTIPLE_SHIPPING_CODE = 'rbmultipleshipping'

export const useCart = () => {
  const context = useContext(cartContext)
  if (context === undefined) {
    throw new Error(`useCart must be used within a CartProvider`)
  }
  return context
}
let addToCartPayload: ICartPayload
let addToBuyNowPayload: ICartPayload

export function CartProvider(props: PropsWithChildren<any>) {
  const {
    cart,
    isGuest,
    user,
    shippingInformation,
    billingInformation,
    sameAsShipping,
    setCartLoader,
    getCart,
    setCart,
    cartId,
    logInUserItems,
    updatedCartItems,
  } = useContext(UserContext) as IUser
  const { t: translate } = useTranslation('common')
  const [shippingMethods, setShippingMethods] = useState([])
  const [loadingShipping, setLoadingShipping] = useState(false)
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState([])
  const [updatingShipping, setUpdatingShipping] = useState(false)
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<any>()
  const [isOnlyPickUp, setIsOnlyPickup] = useState(false)
  const [isPartial, setIsPartial] = useState(false)
  const [isOnlyDelivery, setIsOnlyDelivery] = useState(false)
  const [isUpdatingQty, setIsUpdatingQty] = useState(false)
  const [isBuyNow, setIsBuyNow] = useState(false)
  const [temporaryToken, setTemporaryToken] = useState('')
  const [isMiniCartitem, setIsMiniCartItem] = useState(null)
  const [isPromoApplied, setisPromoApplied] = useState(false)
  const [freeOmsshippingMethodAvailable, setFreeOmsshippingMethodAvailable] =
    useState<boolean>(false)

  const [groupItems, setGroupItems] = useState([
    {
      title: 'Pickup from Store ',
      icon: (color = 'white') => (
        <StoreLocationIcon width="18" height="18" color={color} />
      ),
      items: [],
    },
    {
      title: 'Delivery ',
      icon: (color = 'white') => <FiTruck fontSize={20} color={color} />,
      items: [],
    },
  ])

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('selectedMethod'))
        setSelectedShippingMethod(localStorage.getItem('selectedMethod'))
    } else {
      console.error('localStorage is not available in this environment')
    }
  }, [])

  const [coldPackFee, setColdPackFee] = useState(0)
  const [trigger, setTrigger] = useState(false)
  const { data: session }: any = useSession()
  const router = useRouter()
  const isCartPage = router.pathname === '/cart'
  const isCheckoutPage = router.pathname === '/checkout'

  const [savedCart, saveCart] = useLocalStorage(
    `borobazar-cart`,
    JSON.stringify(initialState),
  )

  const [state, dispatch] = useReducer(cartReducer, JSON.parse(savedCart!))

  useEffect(() => {
    const combinedItems = [...(state.items || []), ...(state.buyNowItems || [])]
    if (combinedItems.length) {
      setGroupItems(checkoutServices.seperateItems(combinedItems, groupItems))
    }
  }, [state.items, state.buyNowItems])

  useEffect(() => {
    if (groupItems[0].items.length > 0 && groupItems[1].items.length === 0) {
      setIsOnlyPickup(true)
      setIsPartial(false)
      setIsOnlyDelivery(false)
    } else if (
      groupItems[0].items.length === 0 &&
      groupItems[1].items.length > 0
    ) {
      setIsOnlyPickup(false)
      setIsPartial(false)
      setIsOnlyDelivery(true)
    } else if (
      groupItems[0].items.length > 0 &&
      groupItems[1].items.length > 0
    ) {
      setIsOnlyPickup(false)
      setIsPartial(true)
      setIsOnlyDelivery(true)
    }
  }, [groupItems])

  useEffect(() => {
    setUpdatingShipping(false)
  }, [cart])

  const estimateShippingPayload = () => {
    let payload: IShppingInfoPayload = {
      country_id: shippingInformation?.country_id,
      postcode: shippingInformation?.postcode,
      region: shippingInformation?.region_code
        ? shippingInformation?.region_code
        : shippingInformation?.region,
      extension_attributes: shippingInformation.extension_attributes,
    }
    if (user) payload.email = user.email
    return { address: payload }
  }

  useEffect(() => {
    saveCart(JSON.stringify(state))
  }, [state, saveCart])

  const [updateCart, { data: updatedCartData }] = useLazyRestApi({
    url: ENDPOINT[isGuest ? 'UPDATE_GUEST_CART' : 'UPDATE_CUSTOMER_CART'],
    method: 'put',
    onComplete: () => {
      if (isCheckoutPage) {
        getCart()
      }
    },
  })

  useEffect(() => {
    if (updatedCartData) {
      const { sku, price, qty } = updatedCartData

      dispatch({
        type: 'UPDATE_ITEM_WITH_QUANTITY_AND_PRICE',
        id: sku,
        quantity: qty,
        price,
      })
    }
  }, [updatedCartData])

  const [deleteCartItem] = useLazyRestApi({
    url: ENDPOINT[isGuest ? 'DELETE_GUEST_CART' : 'DELETE_CUSTOMER_CART'],
    method: 'delete',
    onComplete: () => {
      if (isCheckoutPage) {
        getCart()
      }
    },
  })

  const [deleteCart] = useLazyRestApi({
    url: ENDPOINT[
      isGuest ? 'DELETE_GUEST_ALL_CART' : 'DELETE_CUSTOMER_ALL_CART'
    ],
    method: 'delete',
    onComplete: () => {
      dispatch({ type: 'RESET_CART' })
    },
  })

  useEffect(() => {
    if (logInUserItems?.length)
      dispatch({ type: 'SET_LOGIN_USER_ITEMS', items: logInUserItems })
  }, [logInUserItems])

  useEffect(() => {
    if (updatedCartItems?.length)
      dispatch({ type: 'SET_UPDATED_CART_ITEMS', items: updatedCartItems })
  }, [updatedCartItems])

  const addOrUpdateItemsHandler = (items: Item[]) => {
    dispatch({ type: 'ADD_OR_UPDATE_ITEMS', processItems: items })
  }

  const addItemToCart = async (
    item: Item,
    quantity: number,
    selectedOptions: any,
  ) => {
    if (
      item?.product_type === CONFIGURABLE_PRODUCT_TYPE &&
      (!selectedOptions || Object.keys(selectedOptions).length === 0)
    ) {
      const productUrl = `${ROUTES.PRODUCT}${item?.slug}`

      if (router.pathname === '/[product_pdp]') {
        window.location.href = productUrl
      } else router.push(productUrl)

      return
    }

    item.click_and_collect = item?.isClickCollect ? 'Yes' : 'No'
    item.buyNow = false
    setIsBuyNow(false)
    let currentItem: Item = state?.items?.find(
      (checkItem) => checkItem.sku === item.sku,
    ) as Item

    let checkQuantity = currentItem
      ? (currentItem.quantity ?? 0) + quantity
      : quantity

    if (checkQuantity > ITEM_ADDTOCART_MAX_LIMIT) {
      toast.error(
        translate('text-max-qty-message') + ' ' + ITEM_ADDTOCART_MAX_LIMIT,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-error',
        },
      )
      return true
    }

    dispatch({ type: 'ADD_ITEM_WITH_QUANTITY', item, quantity })
    setIsMiniCartItem(item)

    const authSession: any = getSessionStore() || (await getSession())

    setTrigger(true)

    const payload: ICartPayload = {
      cartItem: {
        qty: quantity,
        quote_id: authSession?.isGuest ? authSession?.cart : cartId?.id,
        sku: item?.sku,
        vendor_id: item?.vendor_id
          ? item?.vendor_id
          : process.env.NEXT_PUBLIC_VENDOR_ID,
        click_and_collect: item?.isClickCollect ? 1 : 0,
      },
    }

    // Add configurable options if productType is 'configurable'
    if (item?.product_type === CONFIGURABLE_PRODUCT_TYPE) {
      payload.cartItem.product_type = item?.product_type
      payload.cartItem.sku = item?.parent_sku
      payload.cartItem.item_sku = item?.sku

      const configurableItemOptions = Object.entries(selectedOptions).map(
        ([option_id, option_value]) => ({
          option_id, // Use key as option_id
          option_value, // Use value as option_value
        }),
      )

      payload.cartItem.product_option = {
        extension_attributes: {
          configurable_item_options: configurableItemOptions,
        },
      }
    }

    ga4Services.addToCart([
      {
        id: item?.id,
        formattedName: item?.formattedName,
        quantity,
        category: item?.category,
        category_l2: item?.category_l2,
        category_l3: item?.category_l3,
        category_l4: item?.category_l4,
        brand: item?.brand,
        price: item?.price,
      },
    ])

    addToCartPayload = payload
  }

  const addBuyNowItemToCart = async (
    item: Item,
    quantity: number,
    token: any,
  ) => {
    setIsBuyNow(true)
    setTemporaryToken(token)
    item.click_and_collect = item?.isClickCollect ? 'Yes' : 'No'
    item.buyNow = true

    if (quantity > ITEM_ADDTOCART_MAX_LIMIT) {
      toast.error(
        translate('text-max-qty-message') + ' ' + ITEM_ADDTOCART_MAX_LIMIT,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-error',
        },
      )
      return true
    }
    dispatch({ type: 'ADD_BUYNOW_ITEM_WITH_QUANTITY', item, quantity, token })
    toast(translate('text-cart-added'), {
      progressClassName: 'fancy-progress-bar',
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: { marginTop: '50px' },
    })
    const authSession: any = await getSession()
    setTrigger(true)
    const payload: ICartPayload = {
      cartItem: {
        qty: quantity,
        quote_id: authSession?.isGuest ? authSession?.cart : cartId?.id,
        sku: item?.sku,
        vendor_id: item?.vendor_id
          ? item?.vendor_id
          : process.env.NEXT_PUBLIC_VENDOR_ID,
        click_and_collect: item?.isClickCollect ? 1 : 0,
      },
    }
    ga4Services.addToCart([
      {
        id: item?.id,
        formattedName: item?.formattedName,
        quantity,
        category: item?.category,
        category_l2: item?.category_l2,
        category_l3: item?.category_l3,
        category_l4: item?.category_l4,
        brand: item?.brand,
        price: item?.price,
      },
    ])
    addToBuyNowPayload = payload
  }

  const updateCartItem = async (item: Item, qty: number) => {
    const { id } = item
    if (qty) {
      item.quantity = qty
      dispatch({ type: 'UPDATE_ITEM', id, item })
      await updateCart({
        cartItem: {
          qty: qty,
          quote_id: cartId?.id ? cartId?.id : session?.cart,
        },
        itemId: id,
      })
    }
  }

  const clearItemFromCart = (id: Item['id']) => {
    dispatch({ type: 'REMOVE_ITEM', id })
    deleteCartItem({ itemId: id })
    // callShippingInformation();
  }

  const clearBuyNowItemFromCart = (id: Item['id']) => {
    dispatch({ type: 'REMOVE_BUYNOW_ITEM', id })
    deleteCartItem({ itemId: id })
    // callShippingInformation();
  }

  const isInCart = useCallback(
    (id: Item['id']) => !!getItem(state.items, id),
    [state.items],
  )

  const getItemFromCart = useCallback(
    (id: Item['id']) => getItem(state.items, id),
    [state.items],
  )

  const isInStock = useCallback(
    (id: Item['id']) => inStock(state.items, id),
    [state.items],
  )

  const resetCart = async () => {
    await deleteCart({})
  }

  const logoutResetCart = () => {
    dispatch({ type: 'RESET_CART' })
  }

  const logoutBuyNowResetCart = () => {
    dispatch({ type: 'RESET_BUYNOW_ITEMS' })
  }

  const [
    addToCartApi,
    { data: cartData, error: AddtocartError, body: apiPayload },
  ] = useLazyRestApi({
    url: ENDPOINT[isGuest ? 'GUEST_CART' : 'ADD_TO_CART'],
    method: 'post',
    onComplete: () => {
      if (isCheckoutPage) {
        getCart()
      }
    },
  })

  const addToBuyNowApi = async () => {
    try {
      let buyNowItem

      if (isGuest) {
        const guestResponse = await cartServices.checkoutBuyNow(
          state.buyNowtTemporaryToken,
        )
        buyNowItem = guestResponse?.items?.[0]
        if (guestResponse) setCart(guestResponse)
      } else {
        const customerBuyNowCart = await cartServices.customerCheckoutBuyNow()
        setCart(customerBuyNowCart)
        buyNowItem = customerBuyNowCart?.items?.[0]
      }

      if (buyNowItem?.sku) {
        updateBuyNowItemDeliveryDays(
          buyNowItem.sku,
          buyNowItem.delivery_days_data,
        )
        if (!isCheckoutPage) Router.push(ROUTES.CHECKOUT)
      }
    } catch (error) {
      console.error('Error in addToBuyNowApi:', error)
    }
  }

  const updateBuyNowItemDeliveryDays = (
    itemId: string,
    deliveryDaysData: any,
  ) => {
    dispatch({
      type: 'UPDATE_BUYNOW_ITEM_DELIVERY_DAYS',
      itemId: itemId,
      deliveryDaysData: JSON.parse(deliveryDaysData as string),
    })
  }

  useEffect(() => {
    if (AddtocartError) {
      const id =
        apiPayload?.cartItem?.product_type === CONFIGURABLE_PRODUCT_TYPE
          ? apiPayload?.cartItem?.item_sku
          : apiPayload?.cartItem?.sku

      if (AddtocartError?.response?.data?.message === ITEM_DOESNOT_EXIST_ERROR)
        dispatch({ type: 'REMOVE_ITEM', id })
      else
        dispatch({
          type: 'REMOVE_ITEM_OR_QUANTITY',
          id,
          quantity: apiPayload?.cartItem?.qty,
        })
      toast.error(AddtocartError?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-error',
        autoClose: 2000,
      })
    }
  }, [AddtocartError])

  useEffect(() => {
    if (trigger && (session?.token || session?.isGuest) && isBuyNow) {
      addToBuyNowApi()
      setTrigger(false)
    } else if (trigger && (session?.token || session?.isGuest) && !isBuyNow) {
      addToCartApi(addToCartPayload)
      setTrigger(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, session])

  const callShippingInformation = async () => {
    if (shippingInformation?.postcode) {
      setCartLoader(true)
      try {
        let shippingMethodsResponse =
          await cartServices.estimatedShippingMethods(
            isGuest,
            estimateShippingPayload(),
          )
        if (shippingMethodsResponse) setLoadingShipping(false)
        setShippingMethods(shippingMethodsResponse)

        let firstMultipleShippingMethod
        const freeOmsshippingMethod = shippingMethodsResponse.find(
          (method: {
            carrier_code: string
            method_code: string
            amount: number
          }) =>
            method.carrier_code === 'rbmultipleshipping' &&
            method.amount === 0 &&
            method.method_code.startsWith('omsshipping'),
        )

        setFreeOmsshippingMethodAvailable(!!freeOmsshippingMethod)

        if (freeOmsshippingMethod) {
          firstMultipleShippingMethod = freeOmsshippingMethod
        } else if (localStorage.getItem('selectedMethod')) {
          firstMultipleShippingMethod = shippingMethodsResponse.find(
            (method: { carrier_code: string; method_code: string }) =>
              localStorage.getItem('selectedMethod') ===
              `${method?.carrier_code}_${method?.method_code}`,
          )
        }
        if (!firstMultipleShippingMethod) {
          firstMultipleShippingMethod = shippingMethodsResponse.find(
            (method: { carrier_code: string; method_code: string }) =>
              method.carrier_code === 'rbmultipleshipping',
          )
        }

        setSelectedShippingMethod(firstMultipleShippingMethod)
        localStorage.setItem(
          'selectedMethod',
          `${firstMultipleShippingMethod?.carrier_code}_${firstMultipleShippingMethod?.method_code}`,
        )

        let shippingAddressFromQuoteResponse: any

        if (shippingMethodsResponse.length > 0) {
          cartServices.setSelectedPostalCode(shippingInformation.postcode)
          const payload = cartServices.createShippingInfoPayload({
            shippingInformation,
            billingInformation,
            firstMultipleShippingMethod: firstMultipleShippingMethod,
            sameAsShipping,
            user,
          })
          shippingAddressFromQuoteResponse =
            await cartServices.shippingAddressFromQuote(isGuest, payload)
        }
        if (shippingAddressFromQuoteResponse) {
          setAvailablePaymentMethods(
            shippingAddressFromQuoteResponse?.payment_methods,
          )
          setCart(shippingAddressFromQuoteResponse)
          setCartLoader(false)
          getBuyNowToken(BUYNOW_TEMPORARY_TOKEN) ? addToBuyNowApi() : getCart()
        }
      } catch (error) {
        setCartLoader(false)
        console.error('callShippingInformation:', error)
      }
    } else {
      if (!isBuyNow) {
        setCart(getCart())
      }
      setCartLoader(false)
    }
  }

  const handleShippingMethodSelection = async ({
    shippingMethod,
    addressform,
    billingAddressform,
  }: {
    shippingMethod: IShippingMethod
    addressform: boolean
    billingAddressform: boolean
  }) => {
    setCartLoader(true)
    try {
      setUpdatingShipping(true)
      const multipleShippingMethods = shippingMethods?.filter(
        (availableMethod: { carrier_code: string }) =>
          availableMethod?.carrier_code === MULTIPLE_SHIPPING_CODE,
      )

      const selectedShippingMethodCode = `${shippingMethod?.carrier_code}_${shippingMethod?.method_code}`

      const firstMultipleShippingMethod: any = multipleShippingMethods.find(
        (method: { method_code: string }) =>
          method.method_code === selectedShippingMethodCode,
      )

      setSelectedShippingMethod(firstMultipleShippingMethod)
      localStorage.setItem(
        'selectedMethod',
        `${firstMultipleShippingMethod?.carrier_code}_${firstMultipleShippingMethod?.method_code}`,
      )
      if (shippingMethod?.carrier_code === CARRIER_CODE_PUROLATOR) {
        localStorage.setItem(
          'additionalShippingData',
          JSON.stringify(shippingMethod?.extension_attributes),
        )
      } else {
        localStorage.removeItem('additionalShippingData')
      }

      let shippingAddressFromQuoteResponse: any

      cartServices.setSelectedPostalCode(shippingInformation.postcode)
      const payload = cartServices.createShippingInfoPayload({
        shippingInformation,
        billingInformation,
        firstMultipleShippingMethod,
        sameAsShipping,
        user,
        addressform,
        billingAddressform,
      })
      shippingAddressFromQuoteResponse =
        await cartServices.shippingAddressFromQuote(isGuest, payload)

      if (shippingAddressFromQuoteResponse) {
        setAvailablePaymentMethods(
          shippingAddressFromQuoteResponse?.payment_methods,
        )
        setCart(shippingAddressFromQuoteResponse)
        setCartLoader(false)
        getCart()
      }
    } catch (error) {
      setCartLoader(false)
      console.error('handleShippingMethodSelection:', error)
    }
  }

  const value = useMemo(
    () => ({
      ...state,
      addItemToCart,
      addBuyNowItemToCart,
      addToBuyNowApi,
      updateCartItem,
      clearItemFromCart,
      clearBuyNowItemFromCart,
      getItemFromCart,
      isInCart,
      isInStock,
      resetCart,
      logoutResetCart,
      logoutBuyNowResetCart,
      callShippingInformation,
      shippingMethods,
      availablePaymentMethods,
      selectedShippingMethod,
      loadingShipping,
      handleShippingMethodSelection,
      setLoadingShipping,
      updatingShipping,
      setAvailablePaymentMethods,
      setShippingMethods,
      setSelectedShippingMethod,
      groupItems,
      isOnlyPickUp,
      isPartial,
      isOnlyDelivery,
      addOrUpdateItemsHandler,
      isUpdatingQty,
      setIsUpdatingQty,
      isMiniCartitem,
      setIsMiniCartItem,
      freeOmsshippingMethodAvailable,
      isPromoApplied,
      setisPromoApplied,
    }),
    [
      addItemToCart,
      addBuyNowItemToCart,
      addToBuyNowApi,
      updateCartItem,
      getItemFromCart,
      isInCart,
      isInStock,
      state,
      shippingMethods,
      getCart,
      session,
      setAvailablePaymentMethods,
      setShippingMethods,
      setSelectedShippingMethod,
      groupItems,
      isOnlyPickUp,
      isPartial,
      isOnlyDelivery,
      addOrUpdateItemsHandler,
    ],
  )
  return <cartContext.Provider value={value} {...props} />
}
