import cn from 'classnames'
import { InputHTMLAttributes, ReactNode, forwardRef, useState } from 'react'
import { Eye } from '@components/icons/eye-icon'
import { EyeOff } from '@components/icons/eye-off-icon'
import { useTranslation } from 'next-i18next'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  inputClassName?: string
  labelClassName?: string
  errorClassName?: string
  label: string
  name: string
  shadow?: boolean
  error: string | undefined
  icon?: ReactNode
  iconColor?: string
}
const classes = {
  root: 'py-2 px-4 md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input text-[16px] lg:text-sm font-body rounded-md placeholder-[#B3B3B3] transition duration-200 ease-in-out text-brand-dark border-border-two focus:border-2 focus:outline-none focus:ring-0 focus:border-brand h-11 md:h-12',
}
const PasswordInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      className = 'block',
      inputClassName,
      labelClassName,
      errorClassName,
      label,
      name,
      error,
      shadow = false,
      icon,
      iconColor,
      ...rest
    },
    ref,
  ) => {
    const hasError = !!error
    const [show, setShow] = useState(false)

    const { t } = useTranslation()
    const rootClassName = cn(classes.root, inputClassName, {
      '!pl-10': icon,
      'border-red-500': hasError,
    })

    const errorRootClassName = cn(
      'my-2 text-sm text-brand-danger text-opacity-70',
      errorClassName,
    )

    return (
      <div className={className}>
        {label && (
          <label
            htmlFor={name}
            className={`block mb-3 font-normal leading-none cursor-pointer  text-brand-dark  ${
              labelClassName || 'text-xs  opacity-70'
            }`}
          >
            {t(label)}
          </label>
        )}
        <div className="relative">
          {icon && (
            <span className="absolute text-brand-gray text-lg w-8 ml-2.5 mt-3.5">
              {icon}
            </span>
          )}
          <input
            id={name}
            name={name}
            type={show ? 'text' : 'password'}
            ref={ref}
            className={rootClassName}
            autoComplete="off"
            spellCheck="false"
            {...rest}
          />
          <label
            htmlFor={name}
            className="absolute -mt-2 cursor-pointer ltr:right-4 rtl:left-4 top-5 text-brand-dark text-opacity-30"
            onClick={() => setShow((prev) => !prev)}
          >
            {show ? (
              <EyeOff className="w-6 h-6" color={iconColor} />
            ) : (
              <Eye className="w-6 h-6" color={iconColor} />
            )}
          </label>
        </div>
        {error && <p className={errorRootClassName}>{t(error)}</p>}
      </div>
    )
  },
)

export default PasswordInput

PasswordInput.displayName = 'PasswordInput'
