import ManagedDrawer from '@components/common/drawer/managed-drawer'
import ManagedModal from '@components/common/modal/managed-modal'
import { ManagedUIContext } from '@contexts/ui.context'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { ToastContainer } from 'react-toastify'

import { DefaultSeo } from '@components/seo/default-seo'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import { usePreserveScroll } from '../utils/scroll-history'
import axios from 'axios'
import { GoogleTagManager } from '@next/third-parties/google'

// external
import 'react-toastify/dist/ReactToastify.css'

// base css file
import '@assets/css/custom-plugins.css'
import '@assets/css/globals.scss'
import '@assets/css/rc-drawer.css'
import '@assets/css/scrollbar.css'
import '@assets/css/swiper-carousel.scss'
import '@assets/css/instantsearch.css'
import { getDirection } from '@utils/get-direction'
import Script from 'next/script'

function Noop({ children }: PropsWithChildren<{}>) {
  return <>{children}</>
}

const CustomApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) => {
  const queryClientRef = useRef<any>()
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient()
  }
  const router = useRouter()
  const dir = getDirection(router.locale)
  let checked = false
  const isComingSoonEnabled =
    process.env.NEXT_PUBLIC_COMING_SOON_STATUS === 'enabled'
  useEffect(() => {
    document.documentElement.dir = dir
  }, [dir])
  const Layout = (Component as any).Layout || Noop
  usePreserveScroll()

  return (
    <>
      <Script
        defer
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places`}
      />
      <Script
        src="https://js.afterpay.com/afterpay-1.x.js"
        data-analytics-enabled
        async
      ></Script>

      <Script
        defer
        src="https://www.paypalobjects.com/api/checkout.js"
      ></Script>
      <Script src="https://js.braintreegateway.com/web/3.101.0/js/client.min.js" />
      <Script src="https://js.braintreegateway.com/web/3.101.0/js/apple-pay.min.js" />
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_GTM} />
      <SessionProvider session={session}>
        <QueryClientProvider client={queryClientRef.current}>
          {/* @ts-ignore */}

          <ManagedUIContext>
            <>
              <DefaultSeo />
              <Layout pageProps={pageProps}>
                <Component {...pageProps} key={router.route} />
              </Layout>
              <ToastContainer className="sm:min-w-[370px]" />
              <ManagedModal />
              <ManagedDrawer />
            </>
          </ManagedUIContext>
          {/* </Hydrate> */}
        </QueryClientProvider>
      </SessionProvider>
    </>
  )
}

export default appWithTranslation(CustomApp)
