import { useModalAction } from '@components/common/modal/modal.context'
import Button from '@components/ui/button'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { FC, useContext } from 'react'
import RebateLoginForm from './rebate-signin-form'
import { GPO_ELIGIBLE_LINK } from '@constants/index'

const RebateWelcomPopUp: FC<any> = ({ data }) => {
  const { t: translate } = useTranslation(['rebate', 'checkout'])
  const {
    user,
    isRebateSignIn = false,
    selectedCustomerGpoData,
    isPdp,
    getCustomerGpoData,
    setSelectedGpo,
  } = data

  const { closeModal } = useModalAction()
  const { setGpoMember } = useContext(UserContext) as IUser

  const continuePopupData = {
    createAcount: {
      thanks: translate('text-rebate-thanks'),
      topText: translate('text-rebate-top-text'),
      bottomText: translate('text-rebate-bottom-text'),
    },
  }

  const handleContinueRebateEligibility = () => {
    if (typeof window !== 'undefined') {
      const link = user?.gpo_eligible_link || GPO_ELIGIBLE_LINK
      if (link) window.open(link, '_blank')
    }
  }

  const closePopup = () => {
    setGpoMember(null)
    closeModal()
  }

  return (
    <div
      className={cn('w-full md:w-[500px] lg:w-[650px] xl:w-[700px]  relative')}
    >
      <span
        className="focus:outline-none cursor-pointer absolute border-0 bg-white rounded-[1.25rem] flex items-center justify-center w-10 h-10 shadow-[0_0_9px_rgba(0,0,0,0.7)] top-[-16px] right-[-17px] "
        onClick={closePopup}
      >
        ✕
      </span>
      <div className="text-base font-sofia leading-5 mx-auto overflow-hidden rounded-lg bg-brand-light p-5">
        {isRebateSignIn ? (
          <RebateLoginForm
            selectedCustomerGpoData={selectedCustomerGpoData}
            isPdp={isPdp}
            getCustomerGpoData={getCustomerGpoData}
            setSelectedGpo={setSelectedGpo}
          />
        ) : (
          <>
            <h1 className="text-brand-dark font-medium ">
              {continuePopupData.createAcount.thanks}
            </h1>
            <p className="text-[blue] font-semibold mt-3">
              {continuePopupData.createAcount.topText}
            </p>
            <div className="text-center my-3">
              <Button
                className="whitespace-nowrap mt-2 tracking-normal h-11 md:h-12 font-15px md:font-15px"
                variant="formButton"
                onClick={handleContinueRebateEligibility}
              >
                <strong>
                  {translate('text-continue', { ns: 'checkout' })}
                </strong>
              </Button>
            </div>
            <p className="text-brand-dark font-medium">
              {continuePopupData.createAcount.bottomText}
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export default RebateWelcomPopUp
