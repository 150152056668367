import cn from 'classnames'
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react'
import { useTranslation } from 'next-i18next'
import InputMask from 'react-input-mask'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  inputClassName?: string
  labelClassName?: string
  label?: string | ReactNode
  placeholder?: string
  name: string
  error?: string
  type?: string
  shadow?: boolean
  variant?: 'normal' | 'solid' | 'outline' | 'simple' | 'underline'
  mask?: string
  icon?: ReactNode
}
const classes = {
  root: 'py-2 px-4 w-full appearance-none transition duration-150 rounded ease-in-out border text-input text-[16px] lg:text-sm font-body placeholder-[#B3B3B3] min-h-12 transition duration-200 ease-in-out text-brand-dark focus:ring-0',
  normal:
    'bg-gray-100 border-gray-300 focus:shadow focus:text-brand-light focus:border-brand',
  solid:
    'text-brand-dark border-border-two focus:border-2 focus:outline-none focus:border-brand h-11 md:h-12',
  outline: 'border-gray-300 focus:border-brand',
  simple:
    'focus:ring-0 focus:border-brand border-0 border-b-[1px] w-full text-sm px-0 rounded-none	px-0',
  shadow: 'focus:shadow',
  underline:
    'pl-0 bg-transparent border-t-0 border-r-0 border-l-0 border-b !border-b-white outline-none rounded-none !text-base text-white font-medium placeholder:text-gray-300',
}
const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className = 'block',
      label,
      name,
      error,
      placeholder,
      variant = 'normal',
      shadow = false,
      type = 'text',
      inputClassName,
      labelClassName,
      icon,
      mask,
      ...rest
    },
    ref,
  ) => {
    const hasError = !!error // Check if there is an error
    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === 'normal',
        [classes.solid]: variant === 'solid',
        [classes.outline]: variant === 'outline',
        [classes.simple]: variant === 'simple',
        [classes.underline]: variant === 'underline',
        'pl-10': icon,
      },
      {
        [classes.shadow]: shadow,
        'border-red-500': hasError,
      },
      inputClassName,
    )
    const { t } = useTranslation()
    return (
      <div className={className}>
        {label && (
          <label
            htmlFor={name}
            className={`block font-normal text-brand-dark  leading-none mb-3 cursor-pointer ${
              labelClassName || ' text-opacity-70 text-xs'
            }`}
          >
            {label}
          </label>
        )}
        {mask ? (
          <InputMask
            // mask options
            mask={mask}
            alwaysShowMask={false}
            maskPlaceholder=""
            // input options
            type={'text'}
            placeholder={placeholder}
            // react hook form register

            id={name}
            name={name}
            ref={ref}
            className={rootClassName}
            autoComplete="off"
            {...rest}
          />
        ) : (
          <>
            {icon && (
              <span className="absolute text-brand-gray text-xl w-8 ml-2.5 mt-3.5">
                {icon}
              </span>
            )}
            <input
              maxLength={35}
              id={name}
              name={name}
              type={type}
              ref={ref}
              // @ts-ignore
              placeholder={t(placeholder)}
              className={rootClassName}
              autoComplete="off"
              spellCheck="false"
              aria-invalid={error ? 'true' : 'false'}
              {...rest}
            />
          </>
        )}
        {error && (
          <p
            className={`my-2 text-sm text-brand-danger text-opacity-70 ${
              type === ''
            }`}
          >
            {t(error)}
          </p>
        )}
      </div>
    )
  },
)

Input.displayName = 'Input'
export default Input
