import ExpectedDelivery from '@components/product/product-delivery-dates'
import Image from '@components/ui/image'
import { DELIVERY, PICK_UP } from '@constants/index'
import { useCheckout } from '@contexts/checkout/checkout.context'
import { useOrderQuery } from '@framework/order/get-order'
import { getOrderProducts } from '@framework/product/get-product'
import usePrice from '@framework/product/use-price'
import { OrderItem, Product } from '@framework/types'
import { getShipInDays, isSpecialOrder } from '@utils/helper'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

export const OrderItemCard = ({
  product,
  index,
}: {
  product: OrderItem
  index: number
}) => {
  const { t: translate } = useTranslation('common')
  const isSpecialOrderFlag = isSpecialOrder(product?.extension_attributes)
  const shipsInDays = getShipInDays(
    product?.extension_attributes?.ships_in,
    translate,
  )
  const { price: itemTotal } = usePrice({
    amount: product.base_row_total,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const itemsSection = product?.extension_attributes?.click_and_collect
    ? PICK_UP
    : DELIVERY

  return (
    <div className="bg-white rounded-md shadow-md mb-4">
      <div
        className=" flex flex-col md:flex-row items-center md:items-start justify-between font-semibold border-b border-border-base last:border-b-0 lg:gap-[20px] gap-0 px-2"
        key={product.id}
      >
        <div className="flex items-start justify-between  ">
          <div className="w-full max-w-[100px]  lg:max-w-[100px] aspect-[4/3] flex-shrink-0 p-2">
            <Image
              src={product.image}
              width={140}
              height={90}
              loading="eager"
              alt={product.name || 'Product Image'}
              style={{ width: 'auto' }}
              className="object-cover bg-fill-thumbnail mx-auto  max-h-24 rounded-md "
            />
          </div>
          <div className="pt-[20px]">
            <p
              data-testid={`success_${itemsSection}_text_item_name(${index})`}
              className="text-[16px] font-normal"
            >
              {product.name}
            </p>
            {!product?.extension_attributes?.delivery_days_data ? (
              <p className="mt-2 text-sm text-brand-dark ">
                {translate('text-ready-to-ship')}{' '}
                <span
                  className={`${
                    isSpecialOrderFlag ? 'text-yellow' : 'text-brand-green'
                  }`}
                >
                  {shipsInDays?.value} {shipsInDays?.unit}(s)
                </span>
              </p>
            ) : null}
            <ExpectedDelivery
              deliveryBy={product?.extension_attributes?.delivery_days_data}
            />
          </div>
        </div>
        <div className="flex justify-between item-center gap-[20px] mb-2 md:pt-[20px]">
          <div
            data-testid={`success_${itemsSection}_text_item_qty(${index})`}
            className="relative flex items-center justify-center w-8 h-8 bg-fill-secondary text-xs rounded-full"
          >
            {product.qty_ordered}
          </div>
          <div
            className="font-semibold text-[20px] "
            data-testid={`success_${itemsSection}_text_item_price(${index})`}
          >
            {itemTotal}
          </div>
        </div>
      </div>
    </div>
  )
}

export interface orderItems {
  items: {
    [category: string]: HTMLElement[]
  }
  categoryLength: number
}
const OrderDetails: FC<{
  className?: string
  orderDetails: any
  loading: boolean
}> = ({ className = 'pt-10 lg:pt-12', orderDetails, loading }) => {
  const { t: translate } = useTranslation('common')
  const [items, setItems] = useState<orderItems>({
    items: {
      'Pickup Items': [],
      'Delivery items': [],
    },
    categoryLength: 0,
  })
  const [isOnlyPickUp, setIsOnlyPickUp] = useState(false)
  const [isPartial, setIsPartial] = useState(false)

  const {
    query: { id },
  } = useRouter()

  const setOrderItemshandler = async () => {
    let OrderItems: any = { ...items }
    let skusString = ''
    orderDetails?.orderItems?.map((product: OrderItem) => {
      skusString = skusString
        ? skusString + ',' + product.sku
        : skusString + product.sku
    })
    let products = await getOrderProducts(
      skusString,
      orderDetails?.orderItems,
      'en-CA',
    )
    OrderItems = {
      ...OrderItems,
      items: products,
      categoryLength:
        products['Delivery items'].length + products['Pickup Items'].length,
    }
    setIsOnlyPickUp(
      products['Delivery items'].length === 0 &&
        products['Pickup Items'].length > 0,
    )
    setIsPartial(
      products['Delivery items'].length > 0 &&
        products['Pickup Items'].length > 0,
    )
    setItems(OrderItems)
  }
  useEffect(() => {
    setOrderItemshandler()
  }, [])

  const { data: order, isLoading } = useOrderQuery(id?.toString()!)
  const { price: subtotal } = usePrice(
    orderDetails?.orderBaseTotalInclTax && {
      amount: orderDetails?.orderBaseTotalInclTax,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )

  const subtotalWithDiscounts =
    (parseFloat(orderDetails?.orderBaseDiscountAmount) || 0) +
    orderDetails?.orderShippingDiscountAmount
  const { price: subtotalWithDiscount } = usePrice(
    orderDetails?.orderBaseTotalInclTax && {
      amount:
        subtotalWithDiscounts +
        (parseFloat(orderDetails?.orderBaseTotalInclTax) || 0),

      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )

  const { price: subtotalOnReferralRewards } = usePrice(
    orderDetails?.referralAmount && {
      amount: orderDetails?.subtotalOnReferralRewards,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )

  const { price: referralAmount } = usePrice(
    orderDetails?.referralAmount && {
      amount: orderDetails?.referralAmount,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )

  const { price: total } = usePrice({
    amount: orderDetails?.orderBaseGrandTotal,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const { price: discountAmount } = usePrice(
    orderDetails?.orderBaseDiscountAmount && {
      amount:
        orderDetails?.orderBaseGrandTotal -
        parseFloat(orderDetails?.orderBaseDiscountAmount),
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )
  const { price: coldPackFee } = usePrice(
    orderDetails?.orderColdPackFee && {
      amount: orderDetails?.orderColdPackFee,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )
  const { hasStoreCreditApplied } = useCheckout()

  const isFullDiscountCoupon =
    orderDetails?.orderBaseGrandTotal === 0 &&
    orderDetails?.couponCode &&
    !hasStoreCreditApplied?.length
  const { price: orderTax } = usePrice(
    orderDetails?.orderTax && {
      amount: orderDetails?.orderTax,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )

  const { price: shipping } = usePrice(
    orderDetails?.orderBaseShippingAmount && {
      amount: orderDetails?.orderBaseShippingAmount,
      currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
    },
  )
  const { price: customerBalance } = usePrice({
    amount: orderDetails?.orderStoreCreditUsed * -1,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })
  const OrderRow = ({ label, value, additionalClass = '' }) => (
    <div
      className={`odd:bg-fill-secondary flex justify-between py-[5px] ${additionalClass}`}
    >
      <span className="font-normal">{label}:</span>
      <span className="font-normal">{value}</span>
    </div>
  )

  if (loading) return <p>Loading...</p>
  const shippingAmount = Number(orderDetails?.orderBaseShippingAmount) || 0
  const shippingDiscount =
    Number(orderDetails?.orderShippingDiscountAmount) || 0

  const finalShippingCost =
    shippingDiscount > 0
      ? (shippingAmount - shippingDiscount).toFixed(2)
      : shippingAmount.toFixed(2)

  return (
    <div className={className}>
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Left Column */}
        <div className="w-full lg:w-1/2">
          {items.categoryLength &&
            Object.entries(items.items).map(([category, categoryItems]) => {
              if (!categoryItems.length) return null
              return (
                <div key={category}>
                  {/* <h4 className="font-semibold">{category}</h4> */}
                  <div>{categoryItems} </div>
                </div>
              )
            })}
        </div>

        {/* Right Column */}
        <div className="w-full lg:w-1/2">
          <h3 className="text-[20px] lg:text-[24px] font-semibold text-brand-dark">
            {translate('text-your-order-details')}
          </h3>

          {/* Order Details */}
          <div className="mt-2 border-spacing-2 w-full text-sm font-semibold text-brand-dark lg:text-base">
            {/* Rows */}
            <OrderRow
              label={translate('text-sub-total')}
              value={
                <>
                  {(orderDetails?.orderBaseDiscountAmount < 0 ||
                    subtotalOnReferralRewards) && (
                    <del className="ml-2 text-sm text-opacity-50 md:text-15px pr-2 text-brand-dark">
                      {subtotalOnReferralRewards || subtotal}
                    </del>
                  )}

                  {subtotalWithDiscount.replace('-', '')}
                </>
              }
            />
            {!isOnlyPickUp && (
              <OrderRow
                label={translate('text-shipping')}
                value={
                  <>
                    {orderDetails?.orderShippingDiscountAmount > 0 ? (
                      <>
                        <del className="ml-2 text-sm text-opacity-50 md:text-15px pr-2 text-brand-dark">
                          {shipping}
                        </del>
                        {finalShippingCost}
                      </>
                    ) : (
                      shipping || 'Free Shipping'
                    )}
                  </>
                }
              />
            )}
            {coldPackFee && (
              <OrderRow
                label={translate('text-cold-pack-fee')}
                value={
                  <>
                    {/* {orderDetails?.orderBaseGrandTotal !== 0 ? (
                      coldPackFee
                    ) : (
                      <span>$0.00</span>
                    )} */}
                    {isFullDiscountCoupon && (
                      <del className="text-sm text-opacity-50 ltr:ml-auto md:text-15px ltr:pl-3 rtl:pr-3 text-brand-dark ">
                        {coldPackFee}
                      </del>
                    )}
                    <span className="text-sm text-black">
                      {isFullDiscountCoupon ? '$0.00' : coldPackFee}
                    </span>
                  </>
                }
              />
            )}
            {orderTax && (
              <OrderRow label={translate('text-tax')} value={orderTax} />
            )}
            {referralAmount && (
              <OrderRow
                label={translate('text-referral')}
                value={referralAmount}
                additionalClass="text-brand-muted"
              />
            )}
            {orderDetails?.orderStoreCreditUsed && (
              <OrderRow
                label={translate('text-store-credit-used')}
                value={customerBalance}
              />
            )}

            {/* Footer */}
            <div className="border-t border-dark border-dashed mt-5 pt-5">
              <OrderRow
                label={translate('text-total')}
                value={
                  <>
                    {orderDetails?.orderBaseDiscountAmount < 0 && (
                      <del className="ml-2 text-sm text-opacity-50 md:text-15px pr-2 text-brand-darkblue">
                        {discountAmount}
                      </del>
                    )}
                    {total}
                  </>
                }
                additionalClass="font-semibold text-[24px] text-brand-darkblue"
              />
              {isPartial && (
                <OrderRow
                  label={translate('text-pickup-address')}
                  value={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: orderDetails?.pickupAddress,
                      }}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderDetails
