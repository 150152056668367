import { useModalAction } from '@components/common/modal/modal.context'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useContext } from 'react'
import Image from '@components/ui/image'
import { ROUTES } from '@utils/routes'

const RebatePdpPopup: FC<any> = ({ data }) => {
  const { t: translate } = useTranslation(['pdp', 'common'])
  const { gpo_collection } = data
  const router = useRouter()
  const { closeModal, openModal } = useModalAction()
  const { user, isGuest } = useContext(UserContext) as IUser

  const gpoSelectHandler = (gpo) => {
    if (isGuest) {
      router.push(`${ROUTES.SIGN_UP}?gpo_id=${gpo.gpo_id}`)
      closeModal()
    } else {
      openModal('REBATE_WELCOME_POPUP', {
        user,
        isRebateSignIn: true,
        isPdp: true,
        selectedCustomerGpoData: gpo,
      })
    }
  }

  const gpoApplytobeEligibleHandler = (gpoId) => {
    if (isGuest) {
      router.push(`${ROUTES.SIGN_UP}?gpo_id=apply_for_gpo`)
      closeModal()
    } else {
      openModal('REBATE_WELCOME_POPUP', { user })
    }
  }

  return (
    <div className={cn('w-full md:w-[450px]   relative')}>
      <div className="text-base my-5 font-medium text-center text-brand-dark font-sofia leading-5 mx-auto overflow-hidden rounded-lg bg-brand-light p-2 sm:p-5">
        <p>{translate('text-are-you-gpo-member')}</p>
        <p className="mt-5">{translate('text-select-gpo')}</p>

        <div className="grid grid-cols-2 gap-4 items-center my-3">
          {gpo_collection?.map((gpo) => (
            <div
              key={gpo.gpo_id}
              className="cursor-pointer flex justify-center p-1  shadow-[0_0_5px_rgba(0,0,0,0.5)] hover:shadow-[0_0_8px_rgba(0,0,0,0.5)] rounded-md"
              onClick={() => gpoSelectHandler(gpo)}
            >
              <div className="h-[60px]   grid place-items-center">
                <Image
                  src={gpo.logo}
                  width={155}
                  height={150}
                  loading="eager"
                  alt={'gpo group img'}
                  className="object-cover bg-fill-thumbnail my-auto"
                />
              </div>
            </div>
          ))}
        </div>

        <p className="mt-3">
          {translate('text-gpo-not-member')}{' '}
          <span
            className="underline cursor-pointer text-brand lowercase"
            onClick={gpoApplytobeEligibleHandler}
          >
            {translate('click-here', { ns: 'common' })}
          </span>
        </p>
      </div>
    </div>
  )
}

export default RebatePdpPopup
