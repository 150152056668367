import { callRestApi } from '@hooks/useRestApi'

const SERVICE_URLS = {
  SUBSCRIBE_TO_NEWSLETTER: '/fsdcustomer/subscribe-to-newsletter',
  CUSTOMER_GPO_DASHBOARD: '/fsd/gpo/get-customer-gpo-dashboard',
  UPDATE_CUSTOMER_GPO: '/fsd/gpo/update-customer-gpo',
  GPO_AUTH_API: 'https://api-cashback.diningalliance.com/auth',
  GPO_VALIDATE_USER: (payload) =>
    `/fsd/gpo/validate-user?pid=${payload.p_id}&uid=${payload?.u_id}&gid=${payload.gid}`,
}

const subscribeToNewsletter = (data) => {
  return callRestApi(SERVICE_URLS.SUBSCRIBE_TO_NEWSLETTER, 'post', data)
}

const getCustomerGpoDashboard = () => {
  return callRestApi(SERVICE_URLS.CUSTOMER_GPO_DASHBOARD)
}

const updateCustomerGpo = (data) => {
  return callRestApi(SERVICE_URLS.UPDATE_CUSTOMER_GPO, 'post', data)
}

const gpoOptionAuthApi = (data) => {
  return callRestApi(SERVICE_URLS.GPO_AUTH_API, 'post', data)
}

const gpoValidateUserApi = (data) => {
  return callRestApi(SERVICE_URLS.GPO_VALIDATE_USER(data), 'post')
}

const dashboardServices = {
  subscribeToNewsletter,
  getCustomerGpoDashboard,
  updateCustomerGpo,
  gpoOptionAuthApi,
  gpoValidateUserApi,
}

export default dashboardServices
