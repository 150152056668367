import Button from '@components/ui/button'
import Input from '@components/ui/form/input'
import PasswordInput from '@components/ui/form/password-input'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import { LoginInputType } from '@framework/auth/use-login'
import { useSession } from 'next-auth/react'
import Image from '@components/ui/image'
import { useTranslation } from 'next-i18next'
import { FC, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import dashboardServices from '@services/dashboard'
import { toast } from 'react-toastify'
import { useModalAction } from '@components/common/modal/modal.context'
import { GPO_ELIGIBLE_ID } from '@constants/index'

interface RebateLoginForm {
  className?: string
  selectedCustomerGpoData: any
  isPdp?: boolean
  getCustomerGpoData?: any
  setSelectedGpo
}

interface ValidatePayload {
  p_id?: string
  u_id?: string
  gid?: string
}

const RebateLoginForm: FC<RebateLoginForm> = ({
  className,
  selectedCustomerGpoData,
  isPdp,
  getCustomerGpoData,
  setSelectedGpo,
}) => {
  const { t: translate } = useTranslation('rebate')
  const { user, userApi } = useContext(UserContext) as IUser
  const { data: session }: any = useSession()
  const { closeModal } = useModalAction()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputType>()

  const { mutate: gpoValidateApi, isLoading: gpoValidateLoading } = useMutation(
    async (payload: ValidatePayload) =>
      dashboardServices.gpoValidateUserApi(payload),
    {
      onSuccess: async (response: any) => {
        if (response?.platform_id) {
          if (getCustomerGpoData) getCustomerGpoData()
          if (isPdp) userApi(null)
          toast.success(response?.response)
          closeModal()
        }
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-error',
        })
      },
    },
  )

  const validateHandler = (data) => {
    let payload = {
      p_id: data?.activeMerchant?.platformId,
      u_id: user?.id,
      gid: selectedCustomerGpoData?.gpo_id,
    }
    gpoValidateApi(payload)
  }

  const { mutate: callGpoAuthApi, isLoading: gpoAuthLoading } = useMutation(
    async (payload: any) => dashboardServices.gpoOptionAuthApi(payload),
    {
      onSuccess: async (response: any) => {
        if (response?.code == 200) {
          if (response?.data) {
            validateHandler(response?.data)
          }
        } else {
          toast.error('Email or password is wrong', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-error',
          })
        }
      },
      onError: (error: any) => {
        if (error?.response?.data?.data?.message === 'User not found') {
          if (!isPdp) {
            closeModal()
            setSelectedGpo(GPO_ELIGIBLE_ID)
          }
          let showError = isPdp
            ? error?.response?.data?.data?.message
            : translate('text-rebate-login-error')
          toast.error(showError, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-error',
          })
        } else {
          toast.error(error?.response?.data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-error',
          })
        }
      },
    },
  )

  function onSubmit({ email, password }: LoginInputType) {
    let credentials = {
      username: email,
      password: password,
      companyId:
        selectedCustomerGpoData?.gpo_identity ??
        selectedCustomerGpoData?.company_id,
    }
    callGpoAuthApi(credentials)
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="text-brand-dark"
    >
      <div>
        {selectedCustomerGpoData?.logo && (
          <Image
            src={
              isPdp
                ? selectedCustomerGpoData?.logo
                : selectedCustomerGpoData?.logo_url
            }
            width={150}
            height={150}
            loading="eager"
            alt={'gpo group img'}
            style={{ width: 'auto' }}
            className="object-cover bg-fill-thumbnail mb-5"
          />
        )}
      </div>
      <h1 className="text-4xl font-bold">Welcome ! </h1>
      <p>
        Please log in to your Dining Alliance account to start earning rebates
        on eligible purchases through FoodServiceDirect.com
      </p>

      <div className="mt-5">
        <Input
          label="Email"
          type="email"
          variant="solid"
          labelClassName="text-brand-dark"
          placeholder="Email@example.com"
          className=""
          inputClassName="rounded-none"
          {...register('email', {
            required: `${translate('email-required', { ns: 'forms' })}`,
            pattern: {
              value:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: translate('email-error', { ns: 'forms' }),
            },
          })}
          error={errors.email?.message}
          maxLength={50}
        />
        <PasswordInput
          label="Password"
          placeholder={translate('label-password', { ns: 'forms' }) as string}
          className=" mt-3"
          inputClassName="rounded-none"
          labelClassName="text-[16px]"
          errorClassName="text-sm"
          error={errors.password?.message}
          {...register('password', {
            required: `${translate('password-required', { ns: 'forms' })}`,
          })}
        />
        <Button
          type="submit"
          loading={gpoAuthLoading || gpoValidateLoading}
          disabled={gpoAuthLoading || gpoValidateLoading}
          className="w-full mt-8 h-12 text-xl rounded-none"
          variant="formButton"
        >
          LOG IN
        </Button>
      </div>
    </form>
  )
}

export default RebateLoginForm
